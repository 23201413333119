label {
  cursor: pointer;
}

.attender  span {
  color:#9e9ad1;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
}

.attender  input[type="checkbox"] {
  display: none;
}

.attender  input[type="checkbox"] + span:before {
  width: 12px;
  height: 12px;
  border: 1.5px solid #f0f0f0;
  content: "";
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: top;
  border-radius: 50%;
}

.attender  input[type="checkbox"]:checked + span:before {
  /* background: #1460c8; */
  /* border: 1.5px solid #1460c8; */
  /* box-shadow: inset 0px 0px 0px 1px #fff; */
  color: #333;
  content: "";
  text-align: center;
  border-radius: 50%;
}

.attender  input[type="checkbox"]:focus + span::before {
  outline: 0;
}

.pcheckbox:checked + span:before {
  background: #2ecc70b0 ;
  /* transform: scale(1.1); */
}

.lcheckbox:checked + span:before {
  background: #fa8927;
  /* transform: scale(1.1); */
}
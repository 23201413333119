body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #25253D;
  color: white;
}

.app-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header1 {
  padding: 16px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-size: 18px;
  font-weight: bold;
}

.date-picker {
  display: flex;
  align-items: center;
}

.date-input {
  padding: 8px;
  border-radius: 4px;
  /* border: 1px solid #444; */
  margin-right: 8px;
}

.refresh-button {
  background-color: #007BFF;
  color: white;
  padding: 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.note {
  padding-left: 10px;
  color: #2ECC71;
  margin-top: 8px;
  text-align: center;
}

.main-content {
  width: 100%;
  flex: 1;
  padding: 10px;
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}
.dashboard.loading {
  position: relative; /* Ensure loader is positioned correctly */
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: #333; /* Adjust color as needed */
}


.dashboard-card {
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dashboard-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.dashboard-card .dashboard-card-header .icon {
  font-size: 60px;
  margin-right: 8px;
}

.dashboard-card .title {
  font-size: 22px;
  margin-bottom: 6px;
}

.dashboard-card {
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
  text-align: center;
}

.dashboard-card .stats-container {
  display: flex;
  flex-direction: column;
}

.dashboard-card .stats {
  display: flex;
  align-items: center;
  gap: 8px;
}

.change-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: yellow;
  font-weight: 800;
  /* color: white; */
  /* font-weight: bold; */
  /* margin-right: 8px;  */
}

.change-icon.green {
  border: solid 2px green;
  color: green;
}

.change-icon.red {
  border: solid 2px red;
  color: red;
}

.change-icon.primary {
  border: solid 2px black;
  color: rgb(3, 3, 3);
}



.dashboard-card-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 18px;
  padding-left: 10px;
}

.dashboard-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-container {
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}

.total {
  font-size: 24px; 
  font-weight: bold;
  margin-right: 5px; 
  padding-bottom: 2px;
}

.total-text {
  font-size: 16px; 
}

.stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats {
  display: flex;
  align-items: center;
  font-size: 14px; 
}

.stats > div {
  margin-right: 5px;
}

.user-list {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.user-card {
  background-color: #2A2E5E;
  /* border: 1px solid #444; */
  border-radius: 8px;
  margin: 8px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-card .user-info {
  display: flex;
  align-items: center;
}

.user-card .status-indicator {
  width: 8px;
  height: 100%;
  background-color: #2ECC71;
  margin-right: 8px;
}

.user-card .name {
  font-weight: bold;
}

.user-card .area-type{
font-weight: lighter;
}

.reports-circle {
  width: 30px; 
  height: 30px; 
  border-radius: 50%;
  margin-right: 10px;
  background-color: #2ECC71;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  position: relative; 
  cursor: pointer;
  text-decoration: none;
}

.loading-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px; 
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db; 
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 10px;
  font-size: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/* back to this */
.overlay {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: rgba(37, 37, 61, 0.5);
    position: fixed;
    z-index: 9999;
    backdrop-filter: blur(3px);
    text-transform: uppercase;
    color: #25253d;
    font-size: 1.3rem;
    /* color: #fff; */
}

.popup {
    max-width: 470px;
    min-width: 250px;
    max-height: 350px;
    position: absolute;
    padding: 8px 8px;
    background-color: var(--color-white);
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.paymentReminder {
    margin: 10px;
    line-height: 1.5;
  }

  .textDiv {
    text-align: center;
  }

  .closeBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .close {
    width: 10rem;
    height: 3rem;
    /* background-color: black; */
    display: flex;
    justify-content: flex-end;
  }
  
  .dayImage {
    height: 100px;
  }
  
  .daysText {
    font-size: 3rem;
    color: var(--color-red);
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .paymentText {
    font-weight: 500;
  }
  
  .month {
    color: var(--color-red);
    font-weight: bold;
  }
  
  .amount {
    color: var(--color-red);
    font-weight: bold;
  }

  .blockText {
    color: var(--color-red);
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .paynow {
    background-color: var(--color-tertiary);
    padding: 1rem 1rem;
    border: none;
    border-radius: 4px;
    margin-top: 5px;
    font-weight: 500;
    color: var(--color-white);
    text-transform: uppercase;
  }
  
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-primary: #2b2c47;
  --color-primary-light: #353456;
  --color-primary-dark: #25253d;
  --color-secondary: #c7c4e9;
  --color-secondary-dark: #9e9ad1;
  --color-tertiary: #36abf9;
  --color-tertiary-opaque: #36abf933;
  --color-tertiary-light: #3498db;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --color-grey-dark-4: #525270;
  --color-cyan: #1abc9c;
  --color-cyan-opaque: #1abc9c33;
  --color-success: #2ecc71;
  --color-error: #e74c3c;
  --color-error-opaque: #e74c3caa;
  --color-red: #FF3A3A;
  --color-yellow: #f1c40f;
  --color-yellow-dark: #e67e22;
  --color-white: #fff;
  --color-black: #000;
  --line: 1px solid var(--color-primary-dark);
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  min-height: 100vh;
}
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.asterisk {
  color: var(--color-error);
}

/**************************** TOP ANNOUNCEMENTS ****************************/
.announcement-top {
  height: 3rem;
}

.announcement-bar {
  display: flex;
  height: auto;
  gap: 15px;
}
.button {
  height: auto;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
/* Button color styles */
.red {
  background-color: red;
}

.announcement {
  background-color: blue;
}

.warning {
  background-color: orange;
}

.success {
  background-color: green;
}

.announcement-btn {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

/**************************** TOP NAVIGATION ****************************/

.brand {
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
}

.brand__heading {
  color: var(--color-secondary);
  font-size: 2.4rem;
  margin-left: 1rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

.logo {
  height: 4.50rem;
  margin-left: 2rem;
}

.search {
  flex: 0 0 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__input {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: var(--color-primary-dark);
  border: none;
  padding: 0.7rem 2rem;
  border-radius: 10rem;
  width: 90%;
  transition: all 0.2s;
  margin-right: -3.25rem;
}

.search__input:focus {
  outline: none;
  width: 100%;
  background: var(--color-primary-dark);
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: var(--color-secondary);
  opacity: 0.5;
}

.search__input:focus + .search__button {
  background: var(--color-primary-dark);
}

.search__button {
  border: none;
  background: var(--color-primary-dark);
  transform: translateY(-2px);
}

.search__button:focus {
  outline: none;
}

.search__button:active {
  transform: translateY(0.2rem);
}

.search__icon {
  height: 2rem;
  width: 2rem;
  fill: var(--color-secondary-dark);
}

.user-nav {
  align-self: stretch;
  display: flex;
  align-items: center;
}

.user-nav__icon-box {
  position: relative;
}

.user-nav > * {
  height: 100%;
  cursor: pointer;
  padding: 0 2rem;
  display: flex;
  align-items: center;
}

.user-nav > *:hover {
  background-color: var(--color-primary);
}

.user-nav__icon {
  height: 2.25rem;
  width: 2.25rem;
  color: var(--color-secondary);
}

.user-nav__notification {
  background-color: var(--color-primary-dark);
  color: var(--color-white);
  font-size: 0.8rem;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;
  position: absolute;
  top: 1.5rem;
  right: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-nav__user span {
  color: var(--color-secondary);
}

.user-nav__user-photo {
  height: 3.75rem;
  border-radius: 50%;
  margin-right: 1rem;
}

/**************************** SIDE NAVIGATION ****************************/

.side-nav {
  font-size: 1.4rem;
  list-style: none;
  margin-top: 3.5rem;
  padding-left: 0;
}

.side-nav__item {
  position: relative;
}

.side-nav__item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.side-nav__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: var(--color-tertiary);
  transform: scaleY(0);
  transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1),
    background-color 0.1s;
}

.side-nav__item:hover::before,
.side-nav__item--active::before {
  transform: scaleY(1);
  width: 100%;
}

.side-nav__item:active::before {
  background-color: var(--color-primary-light);
}

.side-nav__link:link,
.side-nav__link:visited {
  color: var(--color-grey-light-1);
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  position: relative;
  /* z-index: 10; */
}

.side-nav__icon {
  height: 2.5rem;
  width: 2.5rem;
  fill: currentColor;

  margin-right: 1.3rem;
}

.profile {
  font-size: 1.2rem;
  background-color: var(--color-primary-light);
  border-top: 2px solid var(--color-cyan);
  color: var(--color-grey-light-4);
  text-align: center;
  padding: 2rem 3rem 2rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-pic img {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

.profile-info {
  text-align: left;
}

.profile-info h3 {
  color: var(--color-cyan);
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.profile-info h3,
.profile-info p {
  margin-bottom: 0;
}

.profile-settings {
  background-color: var(--color-primary-dark);
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
}

.profile-settings a {
  text-decoration: none;
}

.profile-settings span {
  height: 100%;
  color: var(--color-secondary-dark);
  font-size: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery {
  display: flex;
}

.gallery__photo {
  display: block;
  width: 100%;
}

.overview {
  display: flex;
  align-items: center;
  border-bottom: var(--line);
}

.overview__heading {
  font-size: 2.25rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 1.5rem 3rem;
}

.overview__stars {
  margin-right: auto;
  display: flex;
}

.overview__icon-star,
.overview__icon-location {
  fill: var(--color-primary);
  width: 1.75rem;
  height: 1.75rem;
}

.overview__location {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.overview__icon-location {
  margin-right: 0.5rem;
}

.overview__rating {
  background-color: var(--color-primary);
  color: var(--color-white);
  margin-left: 3rem;
  padding: 0 2.25rem;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overview__rating-average {
  font-size: 2.25rem;
  font-weight: 300;
  margin-bottom: -3px;
}

.overview__rating-count {
  font-size: 0.8rem;
  text-transform: uppercase;
}

.btn-inline {
  border: none;
  color: var(--color-primary);
  font-size: inherit;
  border-bottom: 1px solid currentColor;
  padding: 2px;
  display: inline-block;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-inline span {
  margin-left: 3px;
  transition: margin-left 0.2s;
}

.btn-inline:hover {
  color: var(--color-grey-dark-1);
}

.btn-inline:hover span {
  margin-left: 8px;
}

.btn-inline:focus {
  outline: none;
  animation: pulsate 0.65s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: none;
  }

  50% {
    transform: scale(1.05);
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.25);
  }

  100% {
    transform: scale(1);
  }
}

.paragraph:not(:last-of-type) {
  margin-bottom: 2rem;
}

.list {
  list-style: none;
  margin: 3rem 0;
  padding: 3rem 0;
  border-top: var(--line);
  border-bottom: var(--line);
  display: flex;
  flex-wrap: wrap;
}

.list__item {
  flex: 0 0 50%;
  margin-bottom: 0.7rem;
}

.list__item::before {
  content: "";
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.7rem;
  background-color: var(--color-primary);
  mask-image: url(../img/chevron-right.svg);
  mask-size: cover;
  -webkit-mask-image: url(../img/chevron-right.svg);
  -webkit-mask-size: cover;
}

.recommend {
  font-size: 1.5rem;
  color: var(--color-grey-dark-3);
}

.recommend__count {
  margin-right: auto;
}

.recommend__friends {
  display: flex;
}

.recommend__photo {
  box-sizing: content-box;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: 3px solid var(--color-white);
}

.recommend__photo:not(:last-child) {
  margin-right: -1.5rem;
}

.review {
  background-color: var(--color-white);
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.1);
  padding: 3rem;
  margin-bottom: 3.5rem;
  position: relative;
  overflow: hidden;
}

.review__text {
  margin-bottom: 2rem;
  z-index: 10;
  position: relative;
}

.review__user {
  display: flex;
  align-items: center;
}

.review__photo {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  margin-right: 1.5rem;
}

.review__user-box {
  margin-right: auto;
}

.review__user-name {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
}

.review__user-date {
  font-size: 1rem;
  color: var(--color-grey-dark-3);
}

.review__rating {
  color: var(--color-primary);
  font-size: 2.2rem;
  font-weight: 300;
}

.review::before {
  content: "\201C";
  color: var(--color-grey-light-2);
  position: absolute;
  top: -2.75rem;
  left: -1rem;
  font-family: sans-serif;
  font-size: 20rem;
  line-height: 1;
  z-index: 1;
}

.cta {
  padding: 3.5rem 0;
  text-align: center;
}

.cta__book-now {
  font-size: 2.2rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}

.btn {
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  border-radius: 10rem;
  border: none;
  background-image: linear-gradient(
    to right,
    var(--color-primary-light),
    var(--color-primary-dark)
  );
  color: var(--color-white);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.btn > * {
  display: inline-block;
  height: 100%;
  width: 100%;
  transition: all 0.2s;
}

.btn__visible {
  padding: 2rem 7.5rem;
}

.btn__invisible {
  position: absolute;
  left: 0;
  top: -100%;
  padding: 2rem 0;
}

.btn:hover {
  background-image: linear-gradient(
    to left,
    var(--color-primary-light),
    var(--color-primary-dark)
  );
}

.btn:hover .btn__visible {
  transform: translateY(100%);
}

.btn:hover .btn__invisible {
  top: 0;
}

.btn:focus {
  outline: none;
  animation: pulsate 1s infinite;
}

.button-dropdown-blue {
  background-color: var(--color-primary-light);
  color: var(--color-white);
  font-size: 1.5rem;
  text-decoration: none;
  padding: 1rem 2rem;
}

.button-dropdown-blue:hover {
  color: var(--color-grey-light-1);
}

/**************************** LAYOUT ****************************/

.asterisk-imp {
  color: #eb054b;
}

.web-app {
  background-color: var(--color-grey-light-1);
  box-shadow: 0 1rem 6rem rgba(0, 0, 0, 0.3);
}

.web-app__heading {
  color: var(--color-secondary);
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.web-app__heading-blue {
  color: var(--color-tertiary);
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

.web-app__heading-blue2 {
  color: var(--color-tertiary);
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.header {
  font-size: 1.4rem;
  height: 7rem;
  background: var(--color-primary-light);
  border-bottom: var(--line);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  background-color: var(--color-primary-dark);
  display: flex;
}

.sidebar {
  background-color: var(--color-primary);
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-trim{
  width: 100px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);
}

.main-content {
  min-height: 100vh;
  background-color: var(--color-primary-dark);
  flex: 1 1;
  width: 80%;
  overflow-x: hidden;
}

.detail {
  background: var(--color-grey-light-1);
  font-size: 1.4rem;
  display: flex;
  padding: 4.5rem;
  border-bottom: var(--line);
}

.description {
  background: var(--color-white);
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.06);
  font-size: 1.4rem;
  flex: 0 0 60%;
  margin-right: 4.5rem;
  padding: 3rem;
}

.user-reviews {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ml-15 {
  margin-left: 15rem !important;
}

/**************************UPDATE PROFILE ****************************/

.profile-page{
   min-height: 100vh;
  background-color: var(--color-primary-dark);
  flex: 1 1;
  width: 80%;
}

.profile-content {
  padding: 3rem 4rem;
}


/**************************** NOTIFICATION ****************************/

.notification-content {
  padding: 3rem 4rem;
}

.notification__box {
  background-color: #353456;
  display: flex;
  padding: 1rem 2rem;
  align-content: center;
  justify-content: space-between;
  border-bottom: 2px solid #25253d;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 5px;
}

.notification__box:hover {
  transform: scale(1.02);
}

.notification__avatar {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  color:var(--color-secondary);
  font-size:25px ;
}

.notification__avatar img {
  height: 5rem;
  width: 5rem;
  flex: 1;
}

.notification__text {
  flex: 10;
}

.notification__text-top {
  display: flex;
  justify-content: space-between;
}

.notification__title {
  color: #c7c4e9;
  font-size: 1.6rem;
  font-weight: 500;
}

.notification__time {
  color: #3498db;
  font-size: 1.2rem;
  font-weight: 600;
}

.notification__description {
  color: #9e9ad1;
  font-size: 1.4rem;
}

/**************************** DASHBOARD ****************************/

.dashboard__content .row {
  background-color: var(--color-primary);
  border-radius: 1rem;
  margin: 3rem 4rem 2rem 4rem;
  padding: 2rem 4rem;
}

.dashboard__content h3 {
  color: var(--color-secondary);
  font-size: 1.5rem;
  font-weight: 500;
}

.dashboard__graph-container {
  position: relative;
}

.dashboard-graph-labels {
  color: var(--color-secondary);
  font-size: 1.6rem;
}

.dashboard__graph {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dashboard__graph > div {
  height: 30rem;
  width: 100%;
  margin: 0 5rem 3rem 0;
  display: flex;
  flex-direction: column;
}

.dashboard__graph h2 {
  display: block;
}

.dashboard__graph div p {
  text-transform: lowercase;
  font-weight: 600;
}

.dashboard__filters {
  margin: 1rem;
  width: 45%;
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
}

.dashboard__filters > div {
  margin: 0 0.5rem;
  width: 100%;
}

.dashboard-metric {
  padding: 0 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-metric p {
  color: var(--color-white);
  font-size: 1.5rem;
  font-weight: 400;
}

.dashboard-metrics {
  border-radius: 50%;
  border: none;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 2rem;
}

.dashboard-metrics__target {
  background-color: var(--color-tertiary);
}

.dashboard-metrics__primary {
  background-color: var(--color-success);
}

.dashboard-metrics__secondary {
  background-color: var(--color-yellow-dark);
}

.dashboard-stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin: 3rem 4rem;
  grid-gap: 2rem;
}

.dashboard-stats > * {
  background-color: var(--color-primary);
  height: 10rem;
  border-radius: 5px;
}

.dashboard-stats__metrics {
  display: flex;
  position: relative;
  cursor: pointer;
}

.dashboard-stats__metrics > * {
  flex-grow: 1;
  flex-shrink: 1;
}

.dashboard-stats__metrics-img {
  flex: 40%;
  position: relative;
}
.percentage-holder{
  display: flex;
  width:55%;
  align-items: center;
  justify-content: center;
}
.percentage-holder h1{
  font-size: 6rem;
  font-weight: 900;
  color:rgba(255, 255, 255, 0.955);
  text-shadow: 3px 3px #5e5e5e;
}
.percentage-holder h2{
  color: var(--color-white);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}
.smaller{
  font-size: 3.5rem !important;
}
.dashboard-stats__metrics-image {
  position: absolute;
  height: 10rem;
  top: 0;
  left: 0;
}

.dashboard-stats__metrics-white-circle {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dashboard-stats__metrics-1,
.dashboard-stats__metrics-6 {
  background: linear-gradient(
    to bottom right,
    var(--color-success),
    var(--color-cyan)
  );
}

.dashboard-stats__metrics-2,
.dashboard-stats__metrics-4 {
  background: linear-gradient(
    to bottom right,
    var(--color-tertiary),
    var(--color-tertiary)
  );
}

.dashboard-stats__metrics-3,
.dashboard-stats__metrics-5 {
  background: linear-gradient(
    to bottom right,
    var(--color-yellow),
    var(--color-yellow-dark)
  );
}

.dashboard-stats__metrics-figures {
  text-align: center;
  flex: 60%;
  padding: 2rem 1rem;
}

.dashboard-stats__metrics-figures h2 {
  color: var(--color-white);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}

.dashboard-stats__metrics-figures p {
  color: var(--color-white);
  font-size: 2.5rem;
  font-weight: 700;
  text-shadow: 2px 2px #6d6c6c;
}

.dashboard-cards {
  margin: 3rem 4rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
}

.dashboard-card {
  background-color: var(--color-primary);
  background-image: url("../img/dashboard-cards-bg.png");
  background-size: cover;
  border-radius: 5px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  padding: 3rem 2rem;
  height: 35rem;

  padding-bottom: 1.5rem;

  text-align: center;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.dashboard-card:hover {
  transform: translateY(-0.5rem);
}

.dashboard-card h3 {
  color: var(--color-secondary);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.dashboard-card__chart {
  margin: 2rem 0;

  height: 15rem;
  width: 15rem;
}

/* .dashboard-card__chart {
  height: 15rem;
  margin: 1rem 0;
} */

.dashboard-card h4 {
  color: var(--color-white);
  font-size: 4rem;
  font-weight: 700;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard-card__details {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.dashboard-card__details p {
  color: var(--color-white);
  font-size: 1.4rem;
  font-weight: 500;
}

.dashboard-card__details-pem {
  background-color: var(--color-secondary);
  border-radius: 50%;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
}

.dashboard-card__details-non-pem {
  background-color: var(--color-tertiary);
  border-radius: 50%;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
}

.dashboard-table {
  border-radius: 1.5rem;
  color: var(--color-white);
  margin: 3rem 4rem;
  overflow: hidden;
}

.dashboard-table table,
.dashboard-table th,
.dashboard-table td {
  border: 2px solid var(--color-primary-dark);
  border-collapse: collapse;
}

.dashboard-table table {
  width: 100%;
}

.dashboard-table table thead {
  background-color: var(--color-primary-light);
}

.dashboard-table table tbody {
  background-color: var(--color-primary);
}

.dashboard-table table th {
  color: var(--color-secondary);
  padding: 1rem 0;
  text-align: center;
  font-family: inherit;
  font-weight: 600;
}

.dashboard-table table td {
  color: var(--color-white);
  font-family: inherit;
  text-align: center;
  padding: 2rem 0;
}

.dashboard__right-nav-pie div {
  height: 25rem;

  /* padding: 3rem; */
  margin: 5rem 0;
}

.dashboard__right-nav-pie p {
  color: var(--color-white);
  font-size: 1.5rem;
  font-weight: 400;
  width: 85%;
  text-align: center;
  margin: 0 auto;
}

.dashboard__live-feed {
  position: relative;
}

.dashboard__live-feed > * {
  background-color: var(--color-primary-dark);
  margin: 1rem 0;
  display: flex;
  padding: 2rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  position: relative;
}

.dashboard__live-feed > * img {
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  margin-right: 1.5rem;
}

.dashboard__live-feed > * h4 {
  color: var(--color-secondary);
  font-size: 1.5rem;
}

.dashboard__live-feed > * p {
  color: var(--color-secondary-dark);
  font-size: 1.2rem;
  font-weight: 400;
}

.dashboard__active {
  background-color: var(--color-success);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  position: absolute;
  top: 10%;
  right: 5%;
}

.dashboard-upcoming-stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
}

/**************************** DCR ****************************/

.dcr__heading {
  margin: 3rem 0rem 0 4rem;
}

.dcr__area {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dcr__button {
  position: relative;
  margin: 2rem auto;
}

.dcr__button-hr {
  color: var(--color-success);
  width: 45%;
  opacity: 1 !important;
}

.dcr__button-hr-first {
  position: absolute;
  top: 25%;
}

.dcr__button-hr-second {
  position: absolute;
  top: 25%;
  right: 0%;
}

.dcr__btn-green-outlined {
  background-color: transparent;
  border: none;
}

.dcr__btn-green-outlined svg {
  color: var(--color-cyan);
  font-size: 4rem;
}

.dcr-table {
  border-radius: 1.5rem;
  color: var(--color-white);
}

.dcr-table table {
  box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.15);
}

.dcr-table .border-none {
  border-collapse: collapse;
  border: none;
}

.dcr-table .border-none td {
  border: 2px solid var(--color-primary-dark);
}

.dcr-table .border-none tr:first-child td {
  border-top: 2px solid var(--color-primary-dark);
}

.dcr-table .border-none tr:last-child td {
  border-bottom: none;
}

.dcr-table .border-none tr td:first-child {
  border-left: none;
}

.dcr-table .border-none tr td:last-child {
  border-right: none;
}

.dcr-table table {
  width: 100%;
}

.dcr-table table thead {
  background-color: var(--color-primary-light);
}

.dcr-table table tbody {
  background-color: var(--color-primary);
}

.dcr-table table th {
  color: var(--color-secondary);
  padding: 1rem 0;
  text-align: center;
  font-family: inherit;
  font-weight: 600;
}

.dcr-table table td {
  color: var(--color-secondary);
  font-family: inherit;
  text-align: center;
  padding: 2rem 0;
}

.dcr__center {
  display: flex;
  justify-content: center;
}

.dcr__center .form__group {
  width: 50%;
}

.dcr__radio {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  color: var(--color-secondary);
}

.dcr__radio div input {
  margin-right: 1rem;
}

.dcr__pob {
  flex-direction: column !important;
}

.dcr__remarks {
  display: flex;
}

.dcr__remarks label {
  color: var(--color-secondary);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  width: 100%;
}

.dcr__remarks textarea {
  width: 350%;
}

.doctor-dcr__content {
  padding: 3rem 4rem;
}

/* .doctor-dcr__content .row {
  margin-top: 1rem;
} */

.doctor-dcr__content .row .col-lg-5 {
  padding-left: 0;
}

.doctor-dcr__content .row-bg {
  background-color: var(--color-primary);
  border-radius: 1rem;
  margin-top: 3rem;
  padding: 3rem;
}

.doctor-dcr__content .progress {
  height: 0.5rem;
  border-radius: 5rem;
  width: 100%;
}

.doctor-dcr__content-visit-details {
  margin: 0 2rem;
  height: 90%;

  background-color: var(--color-primary);
  padding: 2rem;
  border-radius: 1rem;
  color: var(--color-secondary);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.doctor-dcr__content-visit-details-container {
  padding: 2rem;
  height: 100%;
  width: 90%;

  display: flex;
  flex-direction: column;
}

.doctor-dcr__content-visit-details-container > div {
  margin: 2rem 0;
}

.doctor-dcr__content-visit-details-call {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doctor-dcr__content-visit-details-call label {
  margin-left: 1rem;
}

.doctor-dcr__content-visit-details-time {
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doctor-dcr__content-visit-details-time div {
  position: relative;
}

.doctor-dcr__content-visit-details-time label {
  color: var(--color-secondary);
  font-size: 1.5rem;
}

.doctor-dcr__content-visit-details-time input {
  background-color: var(--color-primary-dark);
  border: none;
  color: var(--color-secondary);
  padding: 1.5rem 2rem;
  z-index: 9999;
}

.doctor-dcr__content-visit-details-time input:active,
.doctor-dcr__content-visit-details-time input:focus {
  outline: none;
}

.doctor-dcr__content-visit-details-time
  input[type="time"]::-webkit-calendar-picker-indicator {
  background-color: var(--color-white);
  opacity: 0;
}

.doctor-dcr__content-visit-details-time span {
  position: absolute;
  top: 13%;
  right: 18%;
  pointer-events: none;
  font-size: 2.5rem;
}

.doctor-dcr__calendar {
  width: 100%;
}

/**************************** EDETAILING ****************************/
.e-detailing-parent {
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 1rem;
  background-color: var(--color-primary);
}
.e-detailing-cards-container{
  max-height: 80vh;
  overflow-y: scroll;
}
.edetailing-img-card{
  margin: 10px;
  position: relative;
}
.edetailing-img{
  height: 100%;
  width:100%;
  cursor: pointer;
}
.edetailing-img:hover{
  transform: scale(1.05,1.05);
}
.img-dets-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
.yarl__container{
  background-color: rgba(66, 64, 64, 0.9)
}
.view-more {
  cursor: pointer;
}
/**************************** FORM ****************************/

.form__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.2rem 0;
  width: 42%;
}

.form__group-quantity {
  padding: 1rem 0 !important;
}

.form__group--radio {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.form__group-visit-type {
  margin: 1.5rem 0;
}

.form__group select {
  background-color: transparent;
  border: 2px solid var(--color-tertiary);
  border-radius: 5px;
  color: rgba(158, 154, 209, 0.75);
  font-size: 1.5rem;
  font-weight: 500;
  /* width: 60%; */
  width: 100%;
  padding: 0.6rem 2rem;
  cursor: pointer;
}

.form__group select:focus,
.form__group select:active {
  outline: none;
}

.form__group select::placeholder {
  font-size: 1.5rem;
  font-family: inherit;
}

.form__group select option {
  color: var(--color-secondary-dark);
  font-family: inherit;
  font-size: 1.5rem;
}

.form__group label {
  color: var(--color-secondary);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  width: 100%;
}

.form-select {
  background-color: var(--color-primary) !important;
  /* color: #8384b2; */
  border-radius: 0;
}

.form__txt,
.form__textarea {
  background-color: transparent;
  border: 2px solid var(--color-tertiary);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;
  font-weight: 500;
  /* width: 60%; */
  width: 100%;
  padding: 0.6rem 2rem;
}

.form__txt::placeholder,
.form__textarea::placeholder {
  color: rgba(158, 154, 209, 0.75);
}

.form__txt:active,
.form__txt:focus,
.form__textarea:active,
.form__textarea:focus {
  outline: none;
}

.form__quantity-label {
  color: var(--color-secondary-dark) !important;
  font-size: 1.4rem;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}

/**************************** MULTI SELECT ****************************/

.searchWrapper {
  border: none !important;
  min-height: auto;
  padding: 0 !important;
}

.searchWrapper input {
  border: 2px solid var(--color-tertiary) !important;
  color: var(--color-secondary) !important;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
}

.css-1rhbuit-multiValue {
  /* padding: .3rem .7rem; */
  background-color: var(--color-tertiary) !important;
  border: none !important;
}

.css-1rhbuit-multiValue > div {
  color: #fff !important;
  font-size: 1.4rem;
}

/**************************** MATERIAL UI / TABLE ****************************/

.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root:hover {
  color: #c7c4e9 !important;
  color: var(--color-secondary) !important;
}

.MuiTableRow-root {
  background-color: #2b2c47 !important;
  background-color: var(--color-primary) !important;
}

.MuiInputBase-root,
.MuiMenuItem-root,
.MuiTableCell-root,
.MuiTypography-h6 {
  font-size: 1.6rem !important;
}

.MuiTableFooter-root,
.MuiToolbar-regular {
  background-color: #25253d;
  background-color: var(--color-primary-dark);
  color: #c7c4e9;
  color: var(--color-secondary);
  font-size: 1.6rem;
}

.MuiTableRow-root {
  background-color: #2b2c47;
  background-color: var(--color-primary);
  color: #c7c4e9 !important;
  color: var(--color-secondary) !important;
  font-size: 1.6rem;
}

.MuiIcon-root {
  color: #c7c4e9;
  color: var(--color-secondary);
  font-size: 2.5rem !important;
}

.MuiTableRow-root td div {
  justify-content: center;
}

.MuiPaper-root {
  z-index: 0;
}

.MuiTypography-caption {
  color: #c7c4e9;
  color: var(--color-secondary);
  font-size: 1.6rem !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #c7c4e9 !important;
  border-bottom: 1px solid var(--color-secondary) !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #9e9ad1 !important;
  border-bottom: 2px solid var(--color-secondary-dark) !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #36abf9 !important;
  border-bottom: 2px solid var(--color-tertiary) !important;
}

.MuiTableSortLabel-icon {
  opacity: 0.5 !important;
  color: #9e9ad1;
  color: var(--color-secondary-dark);
}

.MuiTableSortLabel-icon:hover {
  opacity: 1 !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: #36abf9 !important;
  color: var(--color-tertiary) !important;
  opacity: 0.8 !important;
}

.MuiInputBase-input {
  color: #c7c4e9 !important;
  color: var(--color-secondary) !important;
}

.MuiTypography-h6 {
  font-family: "Montserrat", sans-serif !important;
}

/**************************** REACT TABLE ****************************/

.react-table-select {
  background-color: var(--color-primary-dark);
  border: 1.5px solid var(--color-tertiary);
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  color: var(--color-secondary);
  width: 100%;
}

.react-table-select:active,
.react-table-select:focus {
  outline: none;
}

.react-table-pagination {
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  left: 0;
}

.react-table-pagination select {
  padding: 0.5rem 1.5rem !important;
  font-size: 1.6rem;
  background-color: var(--color-primary-light);
  border: none;
  color: var(--color-secondary);
  font-weight: 500;
  width: auto !important;
}

.react-table-pagination select option {
  padding: 0.5rem 1rem;
}

.react-table-pagination select:focus,
.react-table-pagination select:active {
  outline: none;
}
.react-table-pagination .arrow{
  font-size: 1.5rem;
  background-color: var(--color-primary);
  border: none;
  color: var(--color-secondary);
  font-weight: 500;
  border: none;
  border-bottom-right-radius: 1.3rem;

  padding: 0.5rem 1.5rem;

}
.react-table-pagination .arrow button{
  background-color:transparent;
  border: none;
  color: var(--color-secondary);
  padding:0.3rem 1rem;

}
.react-table-pagination .arrow button:hover{
  background-color: #25253da0;
  border: none;
  color: var(--color-secondary);
  padding:0.3rem 1rem;
  transition: all 0.1s cubic-bezier(.17,.67,.83,.67);

}
.react-table-pagination .arrow button:disabled {
  color: #656565e5;
}
.react-table-pagination .arrow button:disabled:hover {
  background-color: transparent;
  color: #656565e5;
}
/** Export button **/
.export-button{
  font-size: 1.5rem;
  background-color: var(--color-primary);
  border: none;
  color: var(--color-secondary);
  font-weight: 500;
  border: none;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
  border-bottom-left-radius: 1.3rem;
}
.export-partition{
  background-color: var(--color-secondary);
  height:100%;
  width:1px;
}

.react-table-input {
  width: 100%;
  border: none;
  background-color: var(--color-primary-dark);
  padding: 0.5rem;
  color: var(--color-secondary);
}

.react-table-input-disabled {
  width: 100%;
  border: none;
  background-color: var(--color-primary);
  padding: 0.5rem;
  color: var(--color-secondary);
}

.react-table-input:active,
.react-table-input:focus {
  outline: none;
}

/**************************** SALES TABLE ****************************/

.sales-table__input {
  background-color: var(--color-primary-dark) !important;
  color: var(--color-secondary) !important;
  border: none;
  border-radius: 5rem;
  padding: 0.4rem 1rem;
  width: 100%;
}

.sales-table__input-disabled {
  background-color: var(--color-primary) !important;
  color: var(--color-secondary) !important;
  border: none;
  border-radius: 5rem;
  padding: 0.4rem 1rem;
  width: 100%;
}

.sales-table__input-disabled:active,
.sales-table__input-disabled:focus,
.sales-table__input:active,
.sales-table__input:focus {
  outline: none;
}

/**************************** BUTTONS ****************************/

.button-submit {
  background-color: var(--color-cyan-opaque);
  border: 1.7px solid var(--color-cyan);
  border-radius: 0.5rem;
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 3rem auto 0 auto;
  color: var(--color-cyan);
  padding: 1rem 8rem;
  transition: all 0.3s;
  font-weight: 600;
}

.button-delete-red {
  background-color: rgba(255, 0, 0, 0.218);
  border: 1.7px solid var(--color-error-opaque);
  border-radius: 0.5rem;
  display: block;
  font-size: 1.5rem;
  font-weight: 100;
  margin: 3rem auto 0 auto;
  color: var(--color-error);
  /* padding: 1rem 8rem; */
  transition: all 0.3s;
  font-weight: 600;
}

.button-cyan-gradient {
  background-color: var(--color-cyan-opaque);
  border: 1.7px solid var(--color-cyan);
  border-radius: 0.5rem;
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  width: fit-content;
  color: var(--color-cyan);
  padding: 1rem 3rem;
  transition: all 0.3s;
  font-weight: 600;
}

.button-blue-gradient {
  background-color: var(--color-tertiary-opaque);
  border: 1.7px solid var(--color-tertiary);
  border-radius: 0.5rem;
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  width: fit-content;
  color: var(--color-tertiary);
  padding: 1rem 3rem;
  transition: all 0.3s;
  font-weight: 600;
}

.button-blue-gradient2 {
  background-color: var(--color-tertiary-opaque);
  border: 1.7px solid var(--color-tertiary);
  border-radius: 0.5rem;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  width: fit-content;
  color: var(--color-tertiary);
  padding: 0.5rem 0.5rem;
  transition: all 0.3s;
  font-weight: 600;
}

.button-red-gradient {
  background-color: #8d100e53;
  border: 1.7px solid #721817;
  border-radius: 0.5rem;
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  width: fit-content;
  color: #f34441eb;
  padding: 1rem 3rem;
  transition: all 0.3s;
  font-weight: 600;
}


.button-delete {
  background-color: #f34541;
  border: none;
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  width: fit-content;
  color: var(--color-white);
  transition: all 0.3s;
  font-weight: 600;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0 0;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.button-delete__text {
  padding: 0 0.5rem 0 1.5rem;
}

.button-delete__icon-container {
  background-color: #000;
  padding: 1rem 1.2rem;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.button-delete__icon {
  font-size: 2rem;
  color: var(--color-error);
}

.button-delete:hover {
  transform: translateY(-0.3rem);
  background-color: #000;
}

.button-delete:hover .button-delete__icon-container {
  background-color: var(--color-error);
}

.button-delete:hover .button-delete__icon {
  color: #fff;
}

.button-delete:hover .button-delete__text {
  color: var(--color-error);
}

.button-submit-green {
  background-color: var(--color-success);
  border: none;
  font-size: 1.5rem;
  font-weight: 500;
  width: fit-content;
  color: var(--color-white);
  transition: all 0.3s;
  font-weight: 600;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0 0;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.button-submit-green__text {
  padding: 0 0.5rem 0 1.5rem;
}

.button-submit-green__icon-container {
  background-color: #000;
  padding: 1rem 1.2rem;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.button-submit-green__icon {
  font-size: 2em;
  color: var(--color-success);
}

.button-submit-green:hover {
  transform: translateY(-0.3rem);
  background-color: #000;
}

.button-submit-green:hover .button-submit-green__icon-container {
  background-color: var(--color-success);
}

.button-submit-green:hover .button-submit-green__icon {
  color: #fff;
}

.button-submit-green:hover .button-submit-green__text {
  color: var(--color-success);
}

.button-submit:hover,
.button-blue-gradient:hover {
  transform: scale(1.03) translateY(-0.3rem);
}

.button-submit:active,
.button-submit:focus,
.button-blue-gradient:active,
.button-blue-gradient:focus {
  transform: scale(1.03) translateY(-0.3rem);
}

.button-disabled {
  background-color: #77777733;
  border: 1.7px solid #777;
  border-radius: 0.5rem;
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  color: #c6c6c6;
  padding: 1rem 4rem;
  transition: all 0.3s;
  font-weight: 600;
}

.button-disabled:active,
.button-disabled:focus {
  outline: none;
}

/**************************** EDIT POPUP ****************************/

.edit-popup {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-secondary);
  background-color: #000000aa;
  height: 400%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.edit-popup-top {
  top: 25%
}

.map-popup__close {
  position: absolute;
  top: -30px;
  right: 2px;
  cursor: pointer;
  color: red; /* Adjust color if needed */
  z-index: 1;
}



.edit-popup__sales {
  top: 50% !important;
}

.edit-content {
  background-color: var(--color-primary-light);
  width: 50%;
  padding: 4rem 6rem;
  border-radius: 0.5rem;
}

.edit-content-top {
  position: fixed;
  top: 45%
}

.edit-content__sales {
  background-color: var(--color-primary-light);
  width: 80%;
  padding: 4rem 6rem;
  border-radius: 0.5rem;
  height: 55rem;
}

.edit-form {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  margin-top: 2rem;
}

.edit-content__sales .edit-form {
  margin-top: 0;
}

.edit-content__sales .edit-form .filter-table {
  max-height: 35rem;
  overflow-y: scroll;
}

.edit-form__expense {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  margin: 1rem 0;
  grid-gap: 2rem;
}

.edit-form__gift {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2rem;
  grid-gap: 2rem;
}

.edit-form-button-container {
  display: flex;
  justify-content: space-between;
}

.edit-popup__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-popup__close {
  color: var(--color-error);
  font-size: 2.5rem;
  cursor: pointer;
}

/**************************** ATTENDANCE ****************************/

.attendance__content .row {
  margin: 3rem 4rem;
}

.attendance__highlights {
  background-color: var(--color-primary-light);
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  margin-top: 1.5rem;
}

.attendance__highlights-icon {
  background-color: var(--color-primary-dark);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
}

/* Change This */
.attendance__highlights-icon svg {
  font-size: 4rem;
}

.attendance__highlights-icon .best-month {
  color: var(--color-yellow-dark);
}

.attendance__highlights-icon .max-target {
  color: var(--color-success);
}

.attendance__highlights-icon .halfDay {
  color: var(--color-yellow);
}

.attendance__highlights-icon .leave {
  color: var(--color-error);
}

.attendance__highlights-info {
  margin: 0 auto 0 2rem;
}

.attendance__highlights-info h4 {
  color: var(--color-secondary);
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0;
}

.attendance__highlights-info p {
  color: var(--color-white);
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.attendance__overview {
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 3rem;
}

.attendance__overview--graph {
  height: 30rem;
  width: 100%;
}

.attendance__overview-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attendance-stats {
  display: flex;
  align-items: center;
}

.attendance-stats__profile {
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 4rem 6rem;
  height: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.attendance-stats__profile h3 {
  color: var(--color-cyan);
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 2rem;
}

.attendance-stats__profile p {
  color: var(--color-white);
  font-size: 1.2rem;
  font-weight: 400;
}

.attendance-stats__profile img {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
}

.attendance-stats__info {
  background-color: var(--color-primary-light);
  border-radius: 0 1rem 1rem 0;
  height: 20rem;
  width: 68%;
}

.attendance-stats__info .row {
  padding-top: 3rem;
}

.attendance-stats__info .target-achieved h3 {
  color: var(--color-cyan);
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
}

.attendance-stats__info .target-achieved .progress-bar {
  background-color: var(--color-cyan);
}

.attendance-stats__info .primary-sales h3 {
  color: var(--color-tertiary);
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
}

.attendance-stats__info .primary-sales .progress-bar {
  background-color: var(--color-tertiary);
}

.attendance-stats__info .secondary-sales h3 {
  color: var(--color-yellow-dark);
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
}

.attendance-stats__info .secondary-sales .progress-bar {
  background-color: var(--color-yellow-dark);
}

.attendance-stats__info .progress {
  margin: 3rem 0;
}

.attendance__content-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attendance__content-filter-right-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.dropdown-menu {
  background-color: var(--color-primary-light);
  font-size: 1.5rem;
  width: 11%;
}

.dropdown-item {
  color: var(--color-white);
  padding: 1rem 3rem;
}

/**************************** TOUR PROGRAM ****************************/

.tp__content {
  padding: 3rem 4rem;
}

.tp__content .row-bg {
  background-color: var(--color-primary);
  border-radius: 1rem;
  margin-top: 3rem;
  padding: 3rem;
}
.tp__content .row-bg2{
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding:1rem ;
}

.tp__content .progress {
  height: 0.5rem;
  border-radius: 5rem;
  width: 100%;
}

.tp__activity {
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 2rem;
}

.tp__grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 3rem;
}

.tp__grid > * {
  background-color: var(--color-primary-light);
}

.tp__activity-types {
  border: 2px solid transparent;
  display: flex;
  border-radius: 5rem;
  overflow: hidden;

  cursor: pointer;
}

.tp__activity-types:active,
.tp__activity-types:link,
.tp__activity-types:visited,
.tp__activity-types___active {
  border: 2px solid var(--color-tertiary);
}

.tp__activity-types-icon {
  background-color: var(--color-primary-dark);
  border-radius: 50%;
  flex: 1;
  padding: 0.7rem;
  padding-top: 0.5rem;

  /* display: flex;
  align-items: center;
  justify-content: center; */

  /* border: 2px solid #25253D; */
}

.tp__activity-types-icon {
  font-size: 2.5rem;
  flex: 0 0 52px;
}

.tp__activity-types-icon-1 {
  color: var(--color-tertiary-light);
}

.tp__activity-types-icon-2 {
  color: var(--color-cyan);
}

.tp__activity-types-icon-3 {
  color: var(--color-yellow-dark);
}

.tp__activity-types-icon-4 {
  color: var(--color-yellow-dark);
}

.tp__activity-types-icon-5 {
  color: var(--color-yellow);
}

.tp__activity-types-icon-6 {
  color: var(--color-tertiary-light);
}

.tp__activity-types-icon-7 {
  color: var(--color-cyan);
}

.tp__activity-types-icon-8 {
  color: var(--color-secondary);
}

.tp__activity-types-icon-9 {
  color: var(--color-success);
}
.tp__activity-types-icon-10{
  color:var(--color-error);
}
.tp__activity-types-icon-11{
  color:var(--color-error-opaque);
}
.tp-indicator-1{
  background:var(--color-tertiary-light);
}
.tp-indicator-2{
  background:var(--color-cyan);
  
}
.tp-indicator-3{
  background: var(--color-yellow-dark);
  
}
.tp-indicator-4{
  background:var(--color-yellow-dark);
  
}
.tp-indicator-5{
  background: var(--color-yellow);
  
}
.tp-indicator-6{
  background:var(--color-tertiary-light);
  
}
.tp-indicator-7{
  background:var(--color-cyan);
}
.tp-indicator-8{
  background: var(--color-secondary);
}
.tp-indicator-9{
  background: var(--color-success);
}
.tp-indicator-10{
  background: var(--color-error);
}
.tp-indicator-11{
  background: var(--color-error-opaque);
}
.tp-indicator-12{
  background: var(--color-secondary);
}
.tp-tags h4{
  display:flex;
  align-items:center;
  color:white ;
 }
 .tp-tags h4 span{
   border-radius: 50%;
   height:1.5rem ;
   width:1.5rem;
   display: inline-block;
   text-align: center;
   color:white;
 }
.tp__activity-types-text {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tp__activity-types-text p {
  color: var(--color-white);
  margin: 0;
  font-size: 1.4rem;
  font-weight: 300;
  margin-left: -1rem;
}

.tp__areas,
.tp__info {
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 1rem 3rem;
  margin: 3rem 4rem !important;
}

.tp-details-box,
.tp-details-box-red {
  background-color: var(--color-primary);
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;

  display: flex;
  align-items: center;
}

.tp-details-box {
  border-bottom: 3px solid var(--color-success);
}

.tp-details-box-red {
  border-bottom: 3px solid var(--color-error);
  min-height: 7.4rem;
}

.tp-details-box__area-type {
  width: 20%;
}

.tp-details-box h3,
.tp-details-box-red h3 {
  font-size: 1.6rem;
  color: var(--color-secondary);
  margin-bottom: 0;
}

.tp-details-box p {
  font-size: 1.4rem;
  color: var(--color-secondary-dark);
  font-weight: 500;
  margin-bottom: 0;
}

/************************* EXPENSE *************************/

.expense {
  padding: 3rem 4rem;
}

.expense__filters {
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 3rem;
  margin: 2rem 0 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expense__filters p {
  color: var(--color-secondary);
  font-weight: 500;
}

.expense__filters p {
  margin-bottom: 0;
}

.expense__filters-duration {
  display: flex;
  align-items: center;
}

.expense__approval {
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 3rem;
  margin: 2rem 0;
}

.expense-form__control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 3rem;
  margin-bottom: 2rem;
}

.expense-form__control label {
  font-weight: 500;
  color: var(--color-secondary);
}

.expense-form__control input {
  width: 50%;
  padding: 0.7rem 2rem;
  border: none;
  background-color: var(--color-primary-dark);
  color: var(--color-secondary);
}

.expense-form__control input:active,
.expense-form__control input:focus {
  outline: none;
}

.expense-form__control input::placeholder {
  color: var(--color-secondary-dark);
  opacity: 0.5;
}

.expense-form__control-info {
  background-color: var(--color-primary-dark);
  color: var(--color-secondary);
  padding: 0.7rem 2rem;
  width: 50%;
}

.expense__approval-manager,
.expense__approval-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expense__approval-manager p,
.expense__approval-admin p {
  color: var(--color-secondary);
}

.expense__approval-icon {
  color: var(--color-success);
  font-size: 2.5rem;
}

.expense-remarks {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.expense-remarks label {
  font-size: 1.6rem;
  color: var(--color-secondary);
  margin-bottom: 1rem;
}

.expense-remarks textarea {
  background-color: var(--color-primary-dark);
  border: none;
  font-size: 1.6rem;
  color: var(--color-secondary);
  padding: 1rem 2rem;
}

.expense-remarks textarea::placeholder {
  font-size: 1.6rem;
  color: var(--color-secondary-dark);
  opacity: 0.5;
}

.expense-remarks textarea:active,
.expense-remarks textarea:focus {
  outline: none;
}

.expense__filters-monthSelection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expense__filters-monthSelection-left-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
}

.expense-calculation {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.expense-calculation__container {
  background-color: var(--color-primary);
  border-radius: 0.5rem;
  padding: 1rem 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expense-calculation__container-icon {
  background-color: var(--color-primary-dark);
  padding: 1rem;
  border-radius: 0.4rem;
}

.expense-calculation__container-icon-money {
  font-size: 3rem !important;
}

.expense-calculation__container-icon-money--pending {
  color: var(--color-yellow);
}

.expense-calculation__container-icon-money--approved {
  color: var(--color-success);
}

.expense-calculation__money {
  margin-left: 1rem;
  padding: 1rem 0;
}

.expense-calculation__money h3 {
  font-size: 1.6rem;
  color: var(--color-secondary);
  margin-bottom: 0;
}

.expense-calculation__money p {
  margin-bottom: 0;
  color: var(--color-secondary-dark);
}

.sort-filter__container {
  margin: 1rem 0;
  height: 4rem;
  width: 4rem;

  background-color: var(--color-primary-dark);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-filter__icon {
  font-size: 2.5rem;
  fill: #9e9ad1;
}

.search-filter__container {
  display: flex;
  align-items: center;
}

.search-filter {
  margin: 0 0;
  padding: 0.6rem 2rem;
  padding-right: 3.5rem;
  /* width: 20rem !important; */
  width: 100%;

  color: rgba(255, 255, 255, 0.9);
  background-color: var(--color-primary-dark);

  border-radius: 50px;
  border: none;

  font-size: 1.5rem;
  font-weight: 500;
}

.search-filter__icon {
  transform: translateX(-3rem);
}

.search-filter::placeholder {
  color: rgba(158, 154, 209, 0.75);
}

.search-filter:active,
.search-filter:focus {
  outline: 1px solid var(--color-tertiary);
}

.filter-table {
  border-radius: 1.5rem;
  color: var(--color-white);
  margin: 2rem 0;

  /* min-height: 30rem; */
  /* width: 155rem; */
  /* height: 100vh; */
  overflow-x: auto;
}

.filter-table::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}

.filter-table table,
.filter-table th,
.filter-table td {
  border: 2px solid var(--color-primary-dark);
  border-collapse: collapse;
}

.filter-table table {
  width: 100%;
}

.filter-table table thead {
  background-color: var(--color-primary-light);
  
}

.filter-table table tbody {
  background-color: var(--color-primary);
}
.filter-table table tfoot {
  background-color: var(--color-primary);
}

.filter-table table th {
  background-color: var(--color-primary-light);
  color: var(--color-secondary);
  padding: 2rem 1rem;
  text-align: center;
  font-size: 1.6rem;
  font-family: inherit;
  font-weight: 600;
  padding-top: -5px;
}
.filter-table table th .th-icon-container{
 display: flex;
 flex-direction: column;
 justify-content: center;
 gap:1rem;
}

.filter-table table td {
  color: var(--color-secondary);
  font-family: inherit;
  text-align: center;
  padding: 2rem 1rem;
}

.mui-table-view-details {
  text-decoration: none;
  color: var(--color-tertiary);
  font-weight: 500;
  background-color: transparent;
  border: none;
}

.mui-table-view-details:hover {
  color: var(--color-tertiary-light);
}

.mui-table-action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mui-table-approve-button {
  font-size: 3rem;
  font-weight: 700;
  color: var(--color-success);
}

.mui-table-reject-button {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-error);
}

/********************* SALES **************************/

.sales-filter {
  background-color: var(--color-primary-light);
  padding: 2rem 3rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
}

.sales-values {
  background-color: var(--color-primary);
  padding: 3rem;
  margin-top: 2rem;
  border-radius: 0.5rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
}

.sales-values label {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  color: var(--color-secondary);
  font-weight: 500;
}

.sales-form-input {
  margin-top: 0.5rem;
  padding: 1rem 1.5rem;
  background-color: var(--color-primary-dark);
  border: none;
  font-size: 1.6rem;
  color: var(--color-secondary);
  width: 100%;
}

.sales-form-input:focus,
.sales-form-input:active {
  outline: none;
}

.sales-form-input::placeholder {
  font-size: 1.5rem;
  color: var(--color-secondary-dark);
  opacity: 0.75;
}

.sales-form-hidden {
  display: none !important;
}


/**************************** PRIMARY SALES ****************************/


.primarysales-filter {
  background-color: var(--color-primary-light);
  padding: 2rem 3rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
}

.primarysales-values {
  background-color: var(--color-primary);
  padding: 3rem;
  margin-top: 2rem;
  border-radius: 0.5rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
}

.primarysales-values label {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  color: var(--color-secondary);
  font-weight: 500;
}

.primarysales-form-input {
  margin-top: 0.5rem;
  padding: 1rem 1.5rem;
  background-color: var(--color-primary-dark);
  border: none;
  font-size: 1.6rem;
  color: var(--color-secondary);
  width: 100%;
}

.primarysales-form-input:focus,
.primarysales-form-input:active {
  outline: none;
}

.primarysales-form-input::placeholder {
  font-size: 1.5rem;
  color: var(--color-secondary-dark);
  opacity: 0.75;
}

.primarysales-form-hidden {
  display: none !important;
}



/**************************** TOGGLE ****************************/

.toggle-label {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
}

.toggle-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-primary-light);
  transition: 0.3s;
  border-radius: 30px;
}

.toggle-label span:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.toggle-label input:checked + span {
  background-color: #00c853;
}
.error input:checked + span {
  background-color: var(--color-error);
}
.toggle-label input:checked + span:before {
  transform: translateX(29px);
}

.toggle-label strong {
  display: none;
}


/**************************** TOGGLE FOR LIGHT BACKGROUND ****************************/

.toggle-label2 {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
}

.toggle-label2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label2 span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-primary-dark);
  transition: 0.3s;
  border-radius: 30px;
}

.toggle-label2 span:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.toggle-label2 input:checked + span {
  background-color: #00c853;
}
.error input:checked + span {
  background-color: var(--color-error);
}
.toggle-label2 input:checked + span:before {
  transform: translateX(29px);
}

.toggle-label2 strong {
  display: none;
}



/**************************** FORM CONTROL ****************************/

.form__control {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}

.form__control-tp {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.form__control-w-100 {
  width: 100%;
}

.form__control label,
.form__control-tp label {
  font-size: 1.6rem;
  color: var(--color-secondary);
  font-weight: 500;
}

/**************************** LEAVE REQUEST ****************************/

.leave-content,
.leave-table {
  padding: 3rem 4rem;
}

.leave-content__reason {
  margin: 0 1rem;
  height: 100%;
  background-color: var(--color-primary-light);
  padding: 2rem;
  border-radius: 1rem;
  color: var(--color-secondary);

  display: flex;
  flex-direction: column;
}

.leave-content label {
  color: var(--color-secondary-dark);
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.leave-content textarea {
  background-color: var(--color-primary);
  border: none;
  color: var(--color-secondary);
  padding: 1rem 2rem;
}

.leave-content textarea:focus {
  outline: none;
}

.leave-content textarea::placeholder {
  color: var(--color-secondary);
  font-weight: 400;
  opacity: 0.4;
}

.leave-hr {
  color: #000;
  height: 2px;
  width: 95%;
  margin: 0 auto;
}

.leave-table table th:nth-child(2) {
  width: 260px !important;
}

.rdrDayDisabled {
  background-color: var(--color-primary) !important;
}

.approved-text {
  color: var(--color-success);
}

.pending-text {
  color: var(--color-yellow);
}

.rejected-text {
  color: var(--color-error);
}

.notsubmitted-text {
  color: var(--color-secondary);
}

/**************************** UTILITIES ****************************/

.cursor-pointer{
  cursor: pointer;
} 
.utility {
  padding: 3rem 4rem;
}

.utility-cards__heading > svg {
  color: var(--color-cyan);
  font-size: 3rem;
}

.utility-cards {
  background-color: var(--color-primary-light);
  background-image: url("../img/utiltity-card-background.png");
  background-position: bottom;
  background-size: cover;
  min-height: 22rem;
  padding: 4rem;
  cursor: pointer;
  transition: all 0.3s;
}

.utility-cards:hover {
  transform: translateY(-0.5rem);
}

.utility-cards__heading {
  display: flex;
  align-items: center;
}

.utility-cards__heading .web-app__heading {
  margin-left: 2rem;
}

.utility-cards__content {
  width: 89%;
  margin-left: auto;
}

.utility-cards__content p {
  color: var(--color-secondary);
  opacity: 0.9;
  font-weight: 300;
  margin-bottom: 0;
}

.utility-cards__content-info {
  display: flex;
  font-size: 1.2rem;
  align-items: center;
}

.utility-cards__content-info span {
  font-size: 2rem;
}

.utility-cards__content-info svg {
  font-size: 2rem;
}

.utility-cards__content-info p {
  margin-left: 1rem;
}

.utility-cards__content-info-one {
  margin-top: 2rem;
}

.utility-cards__content-info-one svg,
.utility-cards__content-info-one p {
  color: var(--color-tertiary);
  opacity: 1;
}

.utility-cards__content-info-two {
  margin-top: 2rem;
}

.utility-cards__content-info-two svg,
.utility-cards__content-info-two p {
  color: var(--color-cyan);
  opacity: 1;
  font-weight: 400;
}

.util-select-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.util-select-user__label {
  color: var(--color-success) !important;
}

.util-select-user__icon {
  color: var(--color-success);
  font-size: 2.5rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

/**************************** REMINDERS ****************************/

.reminders .row {
  padding: 3rem 4rem 0 4rem;
}

.reminder-row:first-child {
  margin: 0 0 0 4rem;
}

.reminder-row:last-child {
  margin-right: 4rem;
}

.reminder-row {
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 3rem;
  border-bottom: 2px solid var(--color-success);
}

.reminders-schedule {
  background-color: var(--color-primary);
  border-radius: 0.5rem;
  padding: 3rem 3rem 0 3rem;
}

.reminders-schedule__heading {
  font-size: 1.6rem;
  color: var(--color-tertiary);
  text-transform: uppercase;
  font-weight: 600;
}

.reminders-schedule input,
.reminders-schedule textarea {
  color: var(--color-white);
  margin-bottom: 2rem;
  border: none;
  background-color: var(--color-primary-light);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  width: 100%;
}

.reminders-schedule input::placeholder,
.reminders-schedule textarea::placeholder {
  color: var(--color-secondary);
  opacity: 0.8;
}

.reminders-schedule input:active,
.reminders-schedule input:focus,
.reminders-schedule textarea:active,
.reminders-schedule textarea:focus {
  outline: none;
}

.reminders .button-submit {
  transform: translateX(-5rem);
}

.reminders-list {
  background-color: var(--color-primary);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  margin-bottom: 3rem;
  height: 26.5rem;
  overflow-y: scroll;
}

.reminders-list ul {
  list-style: none;
  padding: 0 2rem;
}

.reminders-list ul li {
  display: flex;
  align-items: center;
  padding: 1.2rem 3rem 1.2rem 0;
}

.reminders-list ul li:not(:last-child) {
  border-bottom: 1px solid var(--color-tertiary);
}

.reminders-list-icon {
  margin-right: 1.5rem;
}

.reminders-list-icon svg {
  font-size: 3.5rem;
  color: var(--color-success);
}

.reminders-list-content h3 {
  font-size: 1.6rem;
  color: var(--color-tertiary);
}

.reminders-list-content p {
  font-size: 1.3rem;
  color: var(--color-secondary);
}

.reminders-upcoming {
  background-color: var(--color-primary-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
}

.reminders-upcoming svg {
  font-size: 10rem;
  color: var(--color-success);
}

.reminders-upcoming h3 {
  font-size: 1.6rem;
  color: var(--color-tertiary);
  margin-top: 2rem;
}

.reminders-upcoming p {
  font-size: 1.3rem;
  color: var(--color-secondary);
  text-align: center;
  margin-top: 1rem;
}

.reminders-quote {
  background: linear-gradient(to bottom, var(--color-primary-dark), #09090e);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reminders-quote svg {
  color: var(--color-tertiary);
  font-size: 10rem;
}

.reminders-quote p {
  font-size: 1.4rem;
  color: var(--color-secondary);
  text-align: center;
  margin-top: 3rem;
}

.reminders-rhs .row {
  padding: 0 1rem;
}

.reminder-card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
  height: 50vh;
  overflow-y: scroll;
}

.reminder-card {
  border-left: 3px solid var(--color-tertiary);
  border-radius: 0.7rem;
  background-color: var(--color-primary-dark);
  padding: 2rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.06);
  width: 100%;
  /* height: 9rem; */
}

.reminder-card p {
  margin-bottom: 0;
}

.reminder-card-title {
  font-size: 1.6rem;
  color: var(--color-secondary);
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  /* background-color: #4FFFB0;
  border-radius: 30px; */
}

.reminder-card-title-name {
  width: 100px;
  font-size: 1.3rem;
  color: var(--color-secondary);
  font-weight: 600;
  opacity: .5;
  display: flex;
  text-align: center;
  justify-content:center;
  align-items: center;
  background-color: #4FFFB0;
  color: black;
  border-radius: 10px; 
}

.reminder-card-desc {
  font-size: 1.4rem;
  color: var(--color-secondary-dark);
}

.reminder-card-person {
  font-size: 1.4rem;
  color: var(--color-yellow-dark);
  font-weight: 600;
  margin-top: 1.5rem;
}

.reminder-card-person span {
  font-weight: 500;
}

.reminder-card-info {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.reminder-card-date {
  font-size: 1.4rem;
  color: var(--color-success);
  font-weight: 500;
}

.reminder-card-time {
  font-size: 1.4rem;
  color: var(--color-cyan);
  font-weight: 500;
}

.reminder-name-lable{
  color: white;
  font-weight: 500;
  background:  var(--color-tertiary);
  padding: .2rem 1rem;
  border-radius: 1rem;
  margin-right: 1rem;
}
.no-reminder-heading{
  color: var(--color-secondary);
  text-align: center;
  align-items: center;
  font-weight: 500;
  margin: 2rem 0rem;
}
/******************** Utility ***********************/

.utility-inner-tp .row {
  padding: 3rem 4rem;
}

.utility-inner-tp p {
  color: var(--color-secondary-dark);
  font-size: 1.8rem;
  margin-top: 1.2rem;
}

.utility-inner-tp-row {
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.utility-inner-tp__table {
  padding: 0;
  margin-top: 2rem;
}

.utility-inner-tp hr {
  color: #000;
  height: 2px;
  width: 95%;
  margin: 0 auto;
}

.util-table-view-details-link {
  font-size: 1.5rem;
  text-decoration: none;
  color: var(--color-success);
  font-weight: 500;
  transition: all 0.3s;
  display: inline-block;
}

.util-table-view-details-icon {
  font-size: 2.5rem;
  text-decoration: none;
  color: var(--color-tertiary);
  font-weight: 500;
  transition: all 0.3s;
  display: inline-block;
}

.util-table-view-details-link:hover {
  color: var(--color-cyan);
  transform: scale(1.05);
}

.util-inner-info-box-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 1.5rem;
}

.util-inner-info-box {
  background-color: var(--color-primary);
  padding: 2rem;
  border-radius: 0.5rem;
  border-bottom: 2px solid var(--color-success);
}

.util-inner-info-box p,
.util-inner-info-box h3 {
  margin-bottom: 0;
}

.util-inner-info-box h3 {
  font-weight: 600;
  color: var(--color-secondary);
  font-size: 1.6rem;
}

.util-inner-info-box p {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}

.util-inner-location-details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.util-inner-location-box {
  border: 2px solid var(--color-tertiary);
  display: flex;
  align-items: center;
  border-radius: 3rem;
  padding: 1rem 2rem;
}

.util-inner-location-box p {
  margin: 0;
  font-size: 1.5rem;
}

.util-inner-location-box-icon {
  margin-right: 1rem;
  color: var(--color-tertiary);
  font-size: 3rem;
}

.util-inner-pob-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
}

.util-inner-pob-details-box {
  background-color: var(--color-primary);
  border-radius: 0.5rem;
  padding: 2rem;
  border-bottom: 2px solid var(--color-cyan);
}

.util-inner-pob-details-type {
  display: flex;
  align-items: center;
}

.util-inner-pob-details-type h3 {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-secondary);
}

.util-inner-pob-details-type-icon {
  margin-right: 1rem;
  font-size: 2.5rem;
  color: var(--color-yellow);
  font-weight: 700;
}

.util-inner-pob-details-prod {
  display: flex;
  border: 2px solid var(--color-yellow);
  padding: 0.7rem 1.5rem;
  flex-wrap: wrap;
  width: fit-content;
  border-radius: 5rem;
}

.util-inner-pob-details-prod p,
.util-inner-pob-details-sample p {
  color: var(--color-secondary);
  font-size: 1.4rem;
  margin: 0;
}

.util-inner-pob-details-prod-icon {
  color: var(--color-yellow);
  font-size: 2rem;
  margin-right: 1rem;
}

.util-inner-pob-details-prodHeading {
  font-size: 1.5rem;
  color: var(--color-yellow);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.util-inner-pob-details-sampleHeading {
  font-size: 1.5rem;
  color: var(--color-secondary);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.util-inner-pob-details-sample {
  display: flex;
  border: 2px solid var(--color-secondary);
  padding: 0.7rem 1.5rem;
  flex-wrap: wrap;
  width: fit-content;
  border-radius: 5rem;
}

.util-inner-pob-details-sample-icon {
  margin-right: 1rem;
  font-size: 2.5rem;
  color: var(--color-secondary);
  font-weight: 700;
}

.util-inner-remarks-para {
  font-size: 1.6rem !important;
}

.util-inner-workedWith {
  display: flex;
}

.util-inner-workedWith p {
  margin-right: 2rem;
}

.util-tp-query {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  background-color: var(--color-primary);
  border-radius: 0.5rem;
  margin-top: 2rem;
  padding: 2rem 3rem;
  width: 100%;
}

.util-list-filters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  border-radius: 0.5rem;
  padding: 2rem 5rem;
  width: 100%;
}

.util-tp-filter p {
  margin-bottom: 0;
  font-size: 1.6rem;
  color: var(--color-secondary-dark);
  font-weight: 500;
}

.util-inner-tp-types {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.util-inner-types-detail {
  display: flex;
  align-items: center;
  border-radius: 5rem;
  padding: 1rem 2rem;
}

.util-inner-types-detail__doc {
  border: 2px solid var(--color-yellow-dark);
}

.util-inner-types-detail__chem {
  border: 2px solid var(--color-secondary);
}

.util-inner-types-detail__stk {
  border: 2px solid var(--color-yellow);
}

.util-inner-location-types-icon {
  font-size: 2.5rem;
  margin-right: 1rem;
}

.util-inner-location-types-icon__doc {
  color: var(--color-yellow-dark);
}

.util-inner-location-types-icon__chem {
  color: var(--color-secondary);
}

.util-inner-location-types-icon__stk {
  color: var(--color-yellow);
}

.util-inner-types-detail p {
  font-size: 1.6rem;
  margin: 0;
}

.user-target-lump-sum-card {
  background-color: var(--color-primary-dark);
  display: flex;
  width: fit-content;
  padding: 2rem 4rem;
  border-radius: 0.5rem;
  border-bottom: 3px solid var(--color-success);
  align-items: center;
}

.user-target-lump-sum-card p {
  color: var(--color-secondary);
  margin-bottom: 0;
  margin-right: 1rem;
}

.user-target-lump-sum-card span {
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--color-success);
}

/**************************** GIFT MANAGEMENT ****************************/

.gift__filters {
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 3rem;
  margin: 2rem 1rem 0 0;
}

.gift__form {
  display: flex;
  flex-direction: column;
}

.gift__form .row {
  width: 100%;
  align-items: center;
}

.gift__form input {
  background-color: var(--color-primary-dark);
  border: none;
  color: var(--color-secondary);
  padding: 1rem 2rem;
  width: 80%;
}

.gift__form input:focus {
  outline: none;
}

.sample-gift-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
  margin-top: 2rem;
}

/**************************** SIDEBAR ****************************/

.sidebar-inner {
  background-color: var(--color-primary-light);
  height: fit-content;
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.sidebar-inner-heading {
  padding: 3rem 3rem;
  border-bottom: 1px solid var(--color-primary-dark);
}

.sidebar-inner .side-nav {
  margin-top: 0;
}

.sidebar-inner .side-nav__item:not(:last-child) {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-primary-dark);
}

/**************************** ADMIN PANEL ****************************/

.admin-panel_content .row {
  padding: 3rem 4rem;
  justify-content: space-between;
}

.admin-panel_content .row:last-of-type {
  padding-top: 0 !important;
}

.admin-panel__alert {
  background-color: #2ecc7069;
  border-radius: 1rem;
  padding: 2rem 3rem;
  margin-top: 2rem;
}

.admin-panel__alert h2 {
  color: var(--color-success);
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.admin-panel__alert p {
  color: var(--color-white);
  font-size: 1.4rem;
}

.admin-panel__box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2rem;
  margin-top: 3rem;
}

.admin-panel__cards {
  background-image: url("../img/admin-panel-boxes.png");
  background-size: contain;
  height: 26rem;
  width: 26rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.admin-panel__cards-link {
  text-decoration: none;
}

.admin-panel_content .row:last-of-type .admin-panel__cards {
  margin-top: 0 !important;
}

.admin-panel__cards a {
  text-decoration: none;
}

.admin-panel__cards svg {
  color: var(--color-secondary-dark);
  font-size: 8rem;
  margin-bottom: 2rem;
}

/* .admin-panel__cards h3 a {
  color: var(--color)
  text-decoration: none;
} */

.filter-table__sg {
  margin-left: 2rem;
}

.admin-delete-buttons {
  background: transparent;
  border: none;
}

.admin-delete-buttons svg {
  color: var(--color-secondary);
}

.admin-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;
}

.password-input {
  position: relative;
}

.show-password-button {
  position: absolute;
  right: 5%;
  top: 57.5%;
  border: none;
  background-color: transparent;
  transition: all 0.3s;
}

.password-icon {
  font-size: 2.4rem;
}

.password-icon-show {
  color: var(--color-secondary-dark);
}

.password-icon-hide {
  color: var(--color-secondary);
}

.admin-highlighted-span {
  font-weight: 600;
  color: var(--color-secondary);
}

.react-table-view-link {
  color: var(--color-tertiary);
  font-weight: 500;
  cursor: pointer;
}

.user-detail-container {
  padding: 3rem;
  background-color: var(--color-primary);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
}

.user-detail-container-green-border {
  border-bottom: 2px solid var(--color-success);
}

.user-detail-container-yellowDark-border {
  border-bottom: 2px solid var(--color-yellow-dark);
}

.user-detail-box h4 {
  text-transform: uppercase;
  color: var(--color-secondary);
  font-weight: 600;
}

.user-detail-box p {
  color: var(--color-secondary-dark);
  margin-bottom: 0;
}

.designation-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.docter-detail-container {
  padding: 3rem;
  background-color: var(--color-primary);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.name-boxes-doct {
  display: flex;
  flex-wrap: wrap;
}

.docters-name-box {
  padding: 0.8rem 2rem;
  margin: 0.6rem 1rem;
  display: flex;

  width: fit-content;
  align-items: center;
  border-radius: 100px;
  background-color: var(--color-primary-dark);
  white-space: nowrap;
}

.docters-border-yellow-dark {
  border: 1px solid var(--color-yellow-dark);
}

.docters-border-greyLight2 {
  border: 1px solid var(--color-grey-light-4);
}

.docters-border-yellow {
  border: 1px solid var(--color-yellow);
}

.color-yellow {
  color: var(--color-yellow) !important;
}

.docters-icon-yellow-dark {
  color: var(--color-yellow-dark);
}

.docters-icon-greyLight2 {
  color: var(--color-grey-light-4);
}

.docters-name-box p {
  color: var(--color-secondary);
  margin-bottom: 0;
}

.docter-name-box-icons {
  margin-right: 1rem;
  font-size: 2.2rem;
  cursor: pointer;
}

.icon-color-green {
  color: var(--color-success) !important;
  font-size: 2.3rem;
}

.icon-color-tertiary {
  color: var(--color-tertiary);
  font-size: 2.3rem;
}

.icon-color-yellow {
  color: var(--color-yellow);
  font-size: 2.3rem;
}

.icon-color-red {
  color: var(--color-error);
  font-size: 2.3rem;
}

.icon-opacity {
  color: var(--color-grey-dark-4);
  font-size: 2.3rem;
}

.text-opacity {
  color: var(--color-grey-dark-4);
}

.icon-rating {
  color: var(--color-yellow);
}

/* //added css for the todays activity  */
.report-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.report-box {
  width: 30%;
  color: white;
  background-color: #353456;
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.report-count {
  margin-left: 1rem;
}

.stock-icon {
  color: var(--color-yellow-dark);
}


.icon-color-error {
  color: var(--color-error) !important;
  font-size: 2.3rem;
}

/**************************** CREATE AREA & CREATE USERS ****************************/

.area-creation-content,
.manage-users,
.admin-creation-content {
  background-color: var(--color-primary-light);
  margin: 2rem 2rem 2rem 0;
  width: 100%;
}

.area-creation-content__heading,
.area-creation-content__form,
.area-creation-content__info,
.manage-users__heading,
.manage-users__form,
.admin-creation-content__heading,
.admin-creation-content__form,
.admin-creation-content__info {
  padding: 3rem 4rem;
  border-bottom: 1px solid var(--color-primary-dark);
}

.admin-creation-content__heading .web-app__heading {
  margin-bottom: 0;
  margin-left: 1rem;
}

.admin-creation-content__heading {
  display: flex;
  align-items: center;
}

.admin-creation-content__heading svg {
  color: var(--color-secondary);
}

.area-creation-content__form-submit,
.admin-creation-content__form-submit {
  display: flex;
  align-items: flex-end;
}

.area-creation-content__info img,
.admin-creation-content__info img {
  width: 100%;
  margin: 2rem 0;
}

.admin-creation-content__submit {
  display: flex;
  align-items: flex-end;
}

.admin-creation-content__back-button {
  cursor: pointer;
}

.admin-content label {
  color: var(--color-secondary-dark);
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.admin-content input,
.admin-content select,
.admin-content textarea {
  background-color: var(--color-primary);
  border: none;
  color: var(--color-secondary);
  padding: 1rem 2rem;
  width: 100%;
}

.admin-content .date-input {
  border: 2px solid var(--color-tertiary);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

input[type="date"] {
  color: var(--color-secondary-dark);
  font-weight: 500;
}

.admin-content textarea {
  width: 93.5%;
}

.admin-content select {
  padding-right: 13rem;
}

.admin-content input::placeholder,
.admin-content textarea::placeholder {
  color: var(--color-secondary-dark);
  opacity: 0.75;
}

.admin-content input:focus,
.admin-content input:active,
.admin-content select:focus,
.admin-content select:active,
.admin-content textarea:focus,
.admin-content textarea:active {
  outline: none;
}

.admin-content-submit-button {
  display: flex;
  align-items: flex-end;
}

.admin-content-submit-button-left {
  display: flex;
  justify-content: flex-start;
}

/****************************  Custom  ****************************/

table th > div {
  display: flex;

  align-items: center;
  flex-direction: column;
}

.multi-filters {
  display: flex;
  justify-content: space-between;
}

.multi-filters form > div {
  margin: 1.2rem 2rem 1.2rem 0;
  width: auto;
}

.table-link {
  color: var(--color-tertiary);
  text-decoration: none;
  transition: color 100ms linear;
}

.table-link:hover {
  color: var(--color-tertiary-light);
}

.web-app__heading2 {
  color: var(--color-secondary);
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.date-range-container {
  width: 100%;
}

.calendar-container {
  display: flex;
}

.calendar-container .rdrCalendarWrapper {
  border-radius: 10px;

  background: var(--color-primary-light);

  background-image: url("../../../views/public/img/calendar-bg-path.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 107% auto;
}

.calendar-container .rdrDateDisplay {
  display: none;
}

.calendar-container .rdrMonthAndYearPickers > span {
  background: none;
  margin: 0;
}

.calendar-container .rdrMonthAndYearPickers select {
  background: none;
  padding: 0px;
}

.calendar-container select {
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.calendar-container .rdrMonthPicker,
.calendar-container .rdrYearPicker {
  font-size: 1.7rem;
}

.calendar-container .rdrYearPicker select {
  font-weight: 200;
}

.calendar-container .rdrYearPicker select {
  font-weight: 700;
}

.calendar-container .rdrMonth {
  margin-top: 2.2rem;
  width: 100%;
}

.dcr-progress {
  height: 0.75rem !important;
  background-color: #2c6699;
}

.dcr-progress-bar {
  background-color: var(--color-tertiary);
}

.reminders-list-content {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reminderDate {
  color: var(--color-secondary);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reminderDateInput {
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reminderDateInput div {
  position: relative;
}

.reminderDateInput input {
  background-color: var(--color-primary-light);
  border: none;
  color: var(--color-secondary);
  padding: 1.5rem 2rem;
  z-index: 9;
  margin-top: 1rem;
}

.reminderDateInput input:active,
.reminderDateInput input:focus {
  outline: none;
}

.reminderDateInput input[type="time"]::-webkit-calendar-picker-indicator {
  background-color: var(--color-white);
  opacity: 0;
}

.reminderDateInput span {
  position: absolute;
  top: 22%;
  right: 18%;
  pointer-events: none;
  font-size: 2.5rem;
}

.utility-inner-tp table {
  margin-top: 2rem;

  border-radius: 1rem;
  color: var(--color-white);
  overflow: hidden;
}

.utility-inner-tp table,
.utility-inner-tp th,
.utility-inner-tp td {
  border-left: 2px solid var(--color-primary-dark);
  border-right: 2px solid var(--color-primary-dark);
  border-collapse: collapse;
}

.utility-inner-tp table thead {
  background-color: var(--color-primary-dark);
}

.utility-inner-tp table tbody {
  background-color: var(--color-primary);
}

.utility-inner-tp table th {
  color: var(--color-secondary);
  padding: 1rem 0;
  text-align: center;
  font-family: inherit;
  font-weight: 500;
}

.utility-inner-tp table th input {
  background-color: var(--color-primary);
}

.utility-inner-tp table td {
  color: var(--color-secondary-dark);
  font-family: inherit;
  text-align: center;
  padding: 2rem 0;
}

.Toastify__toast-body > div:last-child {
  margin-left: 0.4rem;
  margin-top: 0.2rem;
}

.toast-custom-container {
  padding: 0.3rem 0.5rem !important;
  min-height: 1rem !important;

  font-weight: 700;
  font-size: 1.3rem;
  border-radius: 8px !important;

  box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.15);
}

.toast-success {
  color: #09d96e !important;
  background: linear-gradient(
    90deg,
    rgba(25, 66, 78, 1) 0%,
    rgba(37, 37, 61, 1) 18%
  ) !important;
}

.toast-error {
  color: #eb054b !important;
  background: linear-gradient(
    90deg,
    rgba(79, 33, 70, 1) 0%,
    rgba(37, 37, 61, 1) 18%
  ) !important;
}

.toast-info {
  color:#3498db !important;
  background: linear-gradient(
    90deg,
    rgb(24, 82, 83) 0%,
    rgb(45, 21, 50) 18%
  )!important ;
}

.toast-notify {
  color:#b7db34 !important;
  background: linear-gradient(
    90deg,
    rgb(24, 82, 83) 0%,
    rgb(45, 21, 50) 18%
  )!important ;
}

.Toastify__close-button--light {
  color: var(--color-white) !important;
}

.inner-nav__active {
  background-color: var(--color-primary);
  border-left: 10px solid var(--color-tertiary);
}

.inner-nav__active span {
  margin-left: -10px;
}

/**************************** MEDIA QUERIES ****************************/

@media only screen and (max-width: 68.75em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 56.25em) {
  .description {
    padding: 2rem;
    margin-right: 3rem;
  }

  .description {
    margin-right: 0;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 56.25em) {
}

@media only screen and (max-width: 31.25em) {
  .search {
    order: 1;
    flex: 0 0 100%;
  }
}

@media only screen and (max-width: 56.25em) {
  .side-nav__item:not(:last-child) {
    margin: 0;
  }
}

@media only screen and (max-width: 56.25em) {
  .side-nav__item {
    flex: 1;
  }
}

@media only screen and (max-width: 56.25em) {
  .side-nav__link:link,
  .side-nav__link:visited {
    justify-content: center;
    padding: 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .side-nav__link:link,
  .side-nav__link:visited {
    flex-direction: column;
    padding: 1.5rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .side-nav__icon {
    margin-right: 0;
    margin-bottom: 0.7rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .profile {
    display: none;
  }
}

@media only screen and (max-width: 56.25em) {
  .overview__heading {
    font-size: 1.8rem;
    padding: 1.25rem 2rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .overview__rating {
    padding: 0 1.2rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .overview__rating-average {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .overview__rating-count {
    font-size: 0.5rem;
  }
}

@media only screen and (max-width: 68.75em) {
  .recommend {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 56.25em) {
  .review {
    margin-bottom: 3rem;
    padding: 2rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .cta {
    padding: 2.5rem 0;
  }
}

@media only screen and (max-width: 75em) {
  .web-app {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 31.25em) {
  .header {
    flex-wrap: wrap;
    align-content: space-around;
    height: 11rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .content {
    flex-direction: column;
  }
}

@media only screen and (max-width: 56.25em) {
  .detail {
    padding: 3rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .detail {
    flex-direction: column;
  }
}

/*    Media Queries for Mobile   */

@media (max-width: 420px) {
  .header {
    height: 7rem;
    flex-wrap: nowrap;
  }

  .brand__heading,
  .search,
  .user-nav__user span {
    display: none;
  }

  .side-nav {
    align-items: center;
    display: flex;
    margin-top: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 0;
    height: 10rem;
  }

  .side-nav__item {
    height: 10rem;
    padding: 0 2rem;
  }

  .side-nav__icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .side-nav__item::before {
    height: 10rem;
  }

  /* .main-content {
    width: 100%;
  } */

  .dashboard__graph-container {
    height: 52rem;
  }

  .dashboard__content .row {
    margin: 2rem 2rem;
    padding: 2rem 1rem;
  }

  .dashboard__graph > div {
    margin: 2rem 1rem 2rem 0;
  }

  .dashboard-metric {
    flex-direction: row;
    justify-content: space-between;
  }

  .dashboard-metrics {
    margin-right: 1rem;
  }

  .dashboard-stats {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .dashboard-cards {
    grid-template-columns: 1fr;
  }

  .dashboard__filters {
    left: 0;
    margin: 1rem 0;
  }

  .dashboard-card {
    height: 30rem;
  }

  .dashboard__content .row .col-lg-10 p {
    margin-top: 4rem;
  }

  .sc-ksZaOG {
    width: 85% !important;
  }

  .sc-gKXOVf {
    display: none !important;
  }

  .sc-iBkjds {
    padding: 3rem !important;
  }

  .sc-iqcoie {
    margin-top: 3rem !important;
  }

  .sc-eCYdqJ,
  .sc-iBkjds p {
    width: 100% !important;
  }

  .sc-evZas {
    height: 15rem !important;
    top: unset !important;
    bottom: 10% !important;
    left: 7% !important;
  }

  .sc-iqcoie {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .sc-iqcoie a {
    margin-top: 2rem !important;
  }

  /*    Admin Panel     */

  .admin-panel__cards {
    margin: 0 auto;
  }

  .admin-panel_content .row:last-of-type .admin-panel__cards:first-child {
    margin: 0 auto 3rem 3rem;
  }

  /******************************************************   Layout     **********************************************/

  .doctor-dcr__content,
  .tp__content,
  .expense,
  .utility,
  .attendance__overview,
  .doctor-dcr__content .row-bg,
  .tp__content .row-bg,
  .reminders .row,
  .reminders-schedule,
  .leave-content,
  .leave-table,
  .area-creation-content__heading,
  .area-creation-content__form,
  .area-creation-content__info,
  .manage-users__heading,
  .manage-users__form,
  .admin-creation-content__heading,
  .admin-creation-content__form,
  .admin-creation-content__info {
    padding: 2rem;
  }

  .form__txt,
  .form__textarea {
    width: 190%;
  }

  .form__group,
  .dcr__center .form__group {
    width: 100%;
  }

  /*    DCR    */

  .doctor-dcr__content .row .col-lg-5 {
    padding-right: 0;
  }

  .doctor-dcr__content-visit-details {
    margin: 2rem 0 0 0;
  }

  .doctor-dcr__content-visit-details-container {
    padding: 0 2rem;
  }

  .doctor-dcr__content-visit-details-call {
    flex-direction: column;
    align-items: flex-start;
  }

  .dcr__area {
    flex-direction: column;
    padding: 0;
  }

  .dcr__center {
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
  }

  .dcr__radio {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .dcr__remarks {
    flex-direction: column;
  }

  .dcr__remarks textarea {
    width: 100%;
  }

  /****************** Tour Program ***********************************/

  .tp__activity {
    margin-top: 3rem;
  }

  .tp__grid {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-top: 0;
  }

  .tp__grid {
    margin-top: 2rem;
  }

  .tp__activity-types {
    width: 75%;
    margin: 0 auto;
  }

  /*     Expense Form     */

  .expense form {
    flex-direction: column;
  }

  /*    Utility   */

  .utility .row {
    margin-top: 0 !important;
  }

  .utility-cards {
    padding: 2rem;
    margin-bottom: 2rem;
  }

  /*  Utilties Inner  */

  .rdrDateRangePickerWrapper,
  .rdrMonths {
    flex-direction: column;
  }

  /*    Attendance    */

  .attendance__content .row {
    margin: 2rem;
  }

  .attendance__overview-heading {
    flex-direction: column;
    align-items: flex-start;
  }

  .attendance__overview--graph {
    height: 25rem;
    width: 31rem;
  }

  .attendance-stats {
    flex-direction: column;
  }

  .attendance-stats__info {
    height: 32rem;
    width: 100%;
  }

  .reminders-schedule {
    margin-top: 2rem;
  }

  .reminders .button-submit {
    transform: none;
  }

  .leave-content__reason {
    margin: 2rem 0;
    height: 90%;
  }

  .expense__filters {
    padding: 2rem;
  }

  .expense__filters .multi-filters {
    flex-direction: column;
  }

  /*    Gift Management   */

  .gift__form .row .col-lg-3:not(:first-child) {
    margin-top: 1rem;
  }
}

/*    ScrollBar     */

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: #3498db2a;
  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #36abf9;
  border-radius: 20px;
}

.reminder-card-container::-webkit-scrollbar-thumb {
  background-color: #0b588b;
  border-radius: 20px;
}

/* Call Reports */

.dcs-call-wrapper .web-app__heading {
  color: var(--color-success);
}
.dcs-call-wrapper{
  display: flex;
  /* flex-direction: column; */
}
.call-report-card{
  
    margin: 1rem;
    position: relative;
    
}
.call-report-pic{
  width: 13rem;
  height: 13rem;
  cursor: pointer;
}
.img-call-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  left:1rem;
  
}
.img-name{
  font-size: 1.2rem;
}
.gallery{
  margin-top: 6rem;
  margin-left: 6rem;
  
}
.gallery-img-container{
  width: 44rem;
  max-height: 45rem;
  
  align-items: "flex-start !important";;
  align-content: "flex-start !important";
  overflow-y: scroll;
  overflow-x: hidden;
}
.gallery-img-container::-webkit-scrollbar{
  display: none;
}
.avg_data_call_data{
  color: white;
  display: flex;
  gap: 3rem;
  margin: 0;
  right: 0;
}

.avg_call_data{
  width: 150px;
  height: 85px;
  border-radius: 0.5rem;
  color: white;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  justify-content: center;
  font-weight: 700;
  position: relative;
}
.avg_data_value{
  color: var(--color-white);
  font-size: 2rem;
  font-weight: 700;
  text-shadow: 2px 2px #6d6c6c;
}
.avg_data_label {
  font-size: 1.5rem;
}
/* Call Reports --> Accordion */

.accordion {
  width: 100%;
  column-width: 22em;
  column-gap: 3em;
  max-height: fit-content;
}
.accordion-item {
  border: #2b2c47;
  background-color: pink;
  width: 100%;
  display: inline-block;
  height: fit-content;
}

.accordion-button:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
.accordion-button {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  font-size: 1.8rem;
  padding: 1.5rem;
}
.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c7c4e9'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.custom-accordion-header .accordion-button:after {
  display: none !important;
}
.accordion-button:not(.collapsed) {
  color: var(--color-secondary);

  background-color: var(--color-primary);
}
.accordion-body {
  background-color: var(--color-primary-light);
  color: var(--color-secondary);
  padding: 1.5rem;
}

.accordion-body .acc-heading {
  color: var(--color-yellow-dark);
}

.acc-body-card {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
}

.acc-body-card-content {
  position: relative;
  overflow: auto;
  background-color: var(--color-primary-dark);
  color: white;

  height: 40px;
  width: 100%;
  white-space: nowrap;

  font-size: 1.6rem;
}
.acc-body-card-icon {
  font-size: 4rem;
  color: white;
  padding: 0.9rem 0.5rem;
}
.acc-i-yellow {
  background-color: var(--color-yellow-dark);
}
.acc-i-light-yellow {
  background-color: var(--color-yellow);
}
.acc-i-green {
  background-color: var(--color-success);
}

.acc-body-remarks {
  font-size: 1.6rem;
}

/** no results message **/
.no-results-message{
  color:var(--color-error-opaque);
}
.fetching-results-message{
  color:var(--color-cyan);
}
/** react-calendar-attendance **/
.react-calendar {
  border-radius: 10px;
  width:100%;
  background: var(--color-primary-light);
  color:var(--color-secondary);
  background-image: url("../../../views/public/img/calendar-bg-path.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 107% auto;
  font-family: inherit;

}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  color:var(--color-secondary) ;
 
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
 
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;

}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--color-primary-light);
}
.react-calendar__navigation button[disabled] {
  background-color: var(--color-primary-light);

}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--neighboringMonth abbr{
  background-color: #35345600 !important;
  color:rgba(235, 235, 235, 0.262) !important;

}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 3em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em !important;
  background: none;
  
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--color-primary);
  cursor: default;

}
.react-calendar__tile--now abbr{
  text-decoration: underline white solid 3.5px;
  font-weight:700;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: var(--color-primary);

}

.present abbr{
  border-radius: 50%;
  height:2.7rem  !important;
  width:2.7rem !important;
  background: #2ecc70b0;;
  display: inline-block;
  vertical-align: middle;
  
  color:white;
  
}
.absent abbr{
  border-radius: 50%;
  height:2.7rem  !important;
  width:2.7rem !important;
  background: #e74d3cb0;
  display: inline-block;
  vertical-align: middle;
  color:white;

}
.leave abbr{
  border-radius: 50%;
  height:2.7rem  !important;
  width:2.7rem !important;
  display: inline-block;
  vertical-align: middle;
  color:white;
  background: #fa8927;
  
}
.holiday abbr{
  border-radius: 50%;
  height:2.7rem  !important;
  width:2.7rem !important;
  display: inline-block;
  vertical-align: middle;
  color:white;
  background: var(--color-yellow);
  
}
.attendance-calendar-tags h4{
 display:flex;
 align-items:center;
}
.attendance-calendar-tags h4 span{
  border-radius: 50%;
  height:1.5rem ;
  width:1.5rem;
  display: inline-block;
  text-align: center;
  color:white;
}
.tag-indicator-p{
  background: #2ecc70b0;
  
}
.tag-indicator-a{
  background: #e74d3cb0;

}
.tag-indicator-l{
  background: var(--color-yellow-dark);

}

/**expense-image-carousel**/
.carousel{
 height:fit-content;
 width: fit-content;
}
.carousel-inner{
height:30em;
width:50em;
display: flex;
align-items: center;
}
.carousel-item{
height:100%;
width:100%;
position: relative;
}
.carousel-item img{
  height:100%;
  width:100%;
  object-fit: contain !important;
margin:0;

}
.carousel-control-next{
  right:40px;
}
.carousel-control-prev{
  left:40px;
}

/** new features shower **/
.new-feature__shower{
  border-radius: 3px;
  background-color: var(--color-success);
  margin-left: auto;
  color:var(--color-grey-light-1);
  padding: 0px 6px;
  text-transform: lowercase;
  font-size: 1.5rem;
}

/** approvals sideNav length shower**/
.content-length__shower{
  border-radius: 1rem;
  background-color: var(--color-error-opaque);
  margin-left: auto;
  text-align: center;
  color:var(--color-grey-light-1);
  width:3rem;
  font-size: 1.3rem;
}

 .inner-nav__active .content-length__shower{
  background-color: var(--color-error-opaque);
  align-items: center;
  
}
.inner-nav__active .content-length__shower span{
  margin-left: 1px;
}

.faq{
  color: white;
}

.side-modal{
  z-index: 999;
  position: fixed;
  right: 4%;
  /* top: 4%; */
  bottom: 4%;
  border-radius: 4px;
  padding: 8px 10px;
  background-color: rgb(240, 34, 34);
}

.side-modal-inner{
  display: flex;
  align-items: center;
  gap: 10px;
}

.side-modal-text{
  font-weight: 500;
  color: white;
  align-items: center;
  display: flex;
  gap: 4px;
  text-transform: capitalize;
}

.list-item{
  list-style-type: none;
  border-radius: .5rem;
  color: white;
  align-items: center;
  padding: .5rem 1rem;
  margin: .5rem 0rem;
  border-bottom: 1px solid var(--color-primary-dark);
  background-color: var(--color-tertiary);
}

.primary-sales-button{
  padding: 0.2rem 0.2rem !important;
  font-size: 1.2rem;
  background-color: var(--color-primary-light);
  border: none;
  color: var(--color-secondary);
  font-weight: 500;
  width: auto !important;
}
.primary-sales-button-bg {
  background-color: var(--color-tertiary);
  color:white;
}

.notification_header{
  background-color: var(--color-cyan);
  color: var(--color-grey-dark-1);
  font-size: large;
  text-transform: uppercase;
}
.notification_message{
  background-color: var(--color-primary-dark);
  color:var(--color-grey-light-3);
  font-size: 18px;
}
.family{
  width: 80vw;
  height: 80vh;
  overflow-x: scroll;
  overflow-y: scroll;
  position: relative;
  text-align: center;
}

.family_Box{
  display: block;
  background-color: var(--color-tertiary-light);
  border: 1px solid var(--color-success);
  font-size: 18px;
  padding: 1rem 3rem;
  border: 1.7px solid #2ba506;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  width: fit-content;
  color: var(--color-grey-light-4);
  transition: all 0.3s;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: absolute; */
}

.profit_analyse_box{
  display: inline-block;
  background-color: var(--color-tertiary);
  color: var(--color-grey-light-2);
  padding: 2rem 1.5rem;
  min-width: 320px;
  border-radius: 20px;
  margin-left:auto;
  text-transform: capitalize;
  line-height: normal;
  font-family: "Poppins", sans-serif;
  opacity: 0.9;
}

.profit_analyse_box .row1 {
  font-weight: 500;
  font-size: 1.6rem;
  padding: 0.3rem;
}
.profit_analyse_box .row2 {
  font-weight: 500;
  font-size: 1.6rem;
  padding: 0.3rem;
}
.profit_analyse_box .row3 {
  font-size: 3rem;
  font-weight: 700;
  text-shadow: 3px 4px 0px rgba(61, 60, 60, 0.59);
}
.profit_analyse_box .row4 {
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0.3rem;
  
}

.upload-amount{
  font-size: 1.3rem;
  font-weight: 600;
}

.custom-scrollable {
  overflow-y: auto;
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: rgba(37, 37, 61, 0.5);
  position: fixed;
  z-index: 99999;
  backdrop-filter: blur(3px);
  color: #25253d;
  font-size: 1.5rem;
  display: none;
}

@media screen and (max-width: 768px) {
  .overlay {
    display: block; /* Show popup only on mobile screens */
  }
}
  
  .modal {
    max-width: 500px;
    min-width: 250px;
    height: 350px;
    position: absolute;
    padding: 8px 8px;
    background-color: var(--color-white);
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-secondary);
    padding: 1rem;
  }
  
  .logoWrapper {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 4rem;
    width: auto;
  }
  
  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .title {
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--color-tertiary);
  }
  
  .content {
    padding: 1rem 2rem;
  }
  
  .headingWrapper {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .heading {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
  }
  
  .message {
    color: #374151;
    margin: 1rem 0;
  }
  
  .storeLinks {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
  }
  
  .storeLogo {
    height: 3rem;
    width: auto;
  }
.mobile-holder{
  width: 300px;
  height: 600px;
  background-image: url(../../../../assets/iphone.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  color: #f4f2f2;
  overflow: hidden;
  font-weight: 600;
  font-size: small;
}

.details-container {
  border-bottom: 2px solid #25253d;
  padding-left: 2px;
  word-wrap: break-word;
}
.info-cont{
  color: #2ecc71;
}

.checkbox {
  width: 100%;
  height: 20px;
  margin-top: 12px;
  padding: 0px 2px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #2ecc71;
  
  /* background: #000; */
}
.custom-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: green;
  border-color: green;
  accent-color: green;
}

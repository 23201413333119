.main {
  color: #c7c4e9;
}

.activities {
    background-color: #2b2c47;
    border-radius: 0.5rem;
    padding: 3rem;
    margin: 2rem 1rem 1rem 0;
}

.flexBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexContainer {
  width: 20rem;
}

.checkInContainer
{
    display: flex;
    justify-content: space-between;
}

.checkOutContainer
{
    display: flex;
    justify-content: flex-end;
}

.checkInTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  width: 50%;
  margin-left: 5px;
}

.checkOutTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  width: 50%;
  margin-right: 5px;
}

.greenDot {
  background-color: #21c38b; 
  width: 1rem;
  height: 1rem;
  border-radius: 50%; 
}

.redDot {
  background-color: #e74c3c;
  width: 1rem;
  height: 1rem;
  border-radius: 50%; 
}

.verticalBorder {
  border-left: 1px solid #36abf9; 
  height: 40px;
  margin: 0 50%;
}

.commentBox{
  background-color: #2b2c47;
  border-radius: 10px;
  margin:5px;
  margin-bottom: 10px;
  width: 45%;
}
.commentBox-dark-bg{
  background-color: #353456;
}
.coments-container::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}
.final_report_boxes {
    width: 100%;
    display: flex;
    gap: 2rem;
    align-items: center;
    padding: 2rem 0;
    /* background: red; */
  }
  .final_report_box1 {
    width: 50%;
    padding: 2rem;
    background-color: #1ABC9C;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .final_report_box2 {
    width: 50%;
    padding: 2rem;
    background-color: #36ABF9;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .final_report_box1 .row1 {
    width: 100%;
    font-weight: 500;
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
  }
  .final_report_box1 .row2 {
    width: 100%;
    font-weight: 400;
    font-size: 1.6rem;
    display: flex;
    justify-content: space-between;
  }
  .final_report_box1 .row2 .left, .right{
    width: 50%;
    /* border: 1px solid red; */
  }
  .final_report_box1 .rowBold {
    font-weight: 600;
    font-size: 4rem;
  }

  @media only screen and (max-width: 56.25em) {
    .final_report_box1 .row1 {
      font-size: 1.5rem;
    }
    .final_report_box1 .row2 {
      font-size: 1.2rem;
    }
    .final_report_box1 .rowBold {
      font-weight: 600;
      font-size: 3rem;
    }
  }

@media only screen and (max-width: 37.5em) {
  .final_report_boxes {
    flex-direction: column;
  }
  .final_report_box1 {
    width: 100%;
  }
  .final_report_box2 {
    width: 100%;
  }
}